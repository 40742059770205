import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const messageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setMessage: (state, action) => {
			return action.payload;
		},
		clearMessage: () => {
			return null;
		}
	}
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
