export const languages = [
	{ label: 'Français', value: 1 },
	{ label: 'English', value: 2 },
	{ label: 'Deutsch', value: 3 }
];

export const matchingRequestTypes = [
	{ label: "DEMANDE D'INFO", value: 'FROM_SEARCH' },
	{ label: "ISSU D’UN RAPPROCHEMENT", value: 'FROM_CLIENT_PROJECT' },
	{ label: "ISSU D’UN RAPPROCHEMENT", value: 'FROM_PROPERTY' },
];

export const PROPERTY_TYPE_CONSTANTS = {
	APPARTEMENT: 1,
	HOUSE: 2,
	TERRAIN: 3,
	CHALET: 5,
	LOCAUX_MUR_PRO: 4,
	FOND_DE_COMMERCE: 7,
	DROIT_AU_BAIL: 8,
	IMMEUBLE: 6,
};

export const propertyTypes = [
	{ label: 'Appartement', value: PROPERTY_TYPE_CONSTANTS.APPARTEMENT },
	{ label: 'Maison', value: PROPERTY_TYPE_CONSTANTS.HOUSE },
	{ label: 'Terrain', value: PROPERTY_TYPE_CONSTANTS.TERRAIN },
	{ label: 'Locaux / Mur pro', value: PROPERTY_TYPE_CONSTANTS.LOCAUX_MUR_PRO },
	{ label: 'Fond de commerce', value: PROPERTY_TYPE_CONSTANTS.FOND_DE_COMMERCE },
	{ label: 'Droit au bail', value: PROPERTY_TYPE_CONSTANTS.DROIT_AU_BAIL },
	{ label: 'Chalet', value: PROPERTY_TYPE_CONSTANTS.CHALET },
	{ label: 'Immeuble', value: PROPERTY_TYPE_CONSTANTS.IMMEUBLE },
];

export const country = [
	{ label: 'France', value: "FRA" },
	{ label: 'Suisse', value: "CHE" },
];

export const MANAGEMENT_BROADCASTING_SOFTWARE = [
	{ label: 'Immofacile', value: "Immofacile" },
	{ label: 'Hektor', value: "Hektor" },
	{ label: 'APImmo', value: "APImmo" },
	{ label: 'Netty', value: "Netty" },
	{ label: 'Bunji', value: "Bunji" },
	{ label: 'Autres', value: "Autres" },
];

export const accountTypes = [
	{ label: 'Agent/Agence Immobilière', value: 1 },
	{ label: 'Mandataire', value: 2 },
	{ label: 'Gestionnaire de fortune / Family Office / Marchand de biens', value: 3 },
];

export const numberOfRooms = [
	{ label: '1 pièce', value: 1 },
	{ label: '2 pièces', value: 2 },
	{ label: '3 pièces', value: 3 },
	{ label: '4 pièces', value: 4 },
	{ label: '5 pièces', value: 5 },
	{ label: '6 pièces', value: 6 },
	{ label: '7 pièces', value: 7 },
	{ label: '8 pièces', value: 8 },
	{ label: '9 pièces', value: 9 },
	{ label: '10 pièces et +', value: 10 },
];

export const tolerance = [
	{ label: '0%', value: 0 },
	{ label: '-5%', value: 5 },
	{ label: '-10%', value: 10 },
	{ label: '-15%', value: 15 },
];

export const tolerancePrice = [
	{ label: '0%', value: 0 },
	{ label: '+5%', value: 5 },
	{ label: '+10%', value: 10 },
	{ label: '+15%', value: 15 },
];

export const propertyAnnexes = [
	{ label: 'Garage', value: 1 },
	{ label: 'Place Privative / Parking', value: 2 },
	{ label: 'Piscine', value: 3 },
];

export const honoraryTypes = [
	{ label: 'Vendeur', value: 1 },
	{ label: 'Acquéreur', value: 2 },
];

export const propertyStates = [
	{ label: 'En vente', value: 1 },
	{ label: 'Vendu par THM', value: 2 },
	{ label: 'Vendu par autre plateforme', value: 3 },
	{ label: 'Non vendu', value: 4 },
	{ label: 'Archivé', value: 5 }
];

export const propertyPublishListing = [
	{ label: 'En ligne ✅', value: 1 },
	{ label: 'Hors ligne ⛔️', value: 0 },
];

export const hexColorsEnergyEfficiencyScale = {
	'A': '#4CAF50', // green-500
	'B': '#81C784', // green-300
	'C': '#FFF176', // yellow-300
	'D': '#FFEB3B', // yellow-500
	'E': '#FFB74D', // orange-300
	'F': '#FB8C00', // orange-500
	'G': '#F44336'  // red-500
};

export const nonLoggedInRoutes = ['/login', '/create/step1', '/create/step2', '/verify', '/reset-password', '/reset-password/:token'];
