import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAvatar, getDetail, getSubscriptionStatus, updateDetail } from 'app/services/user';
import { setMessage } from './message';

// const user = JSON.parse(localStorage.getItem('user'));

export const profile = createAsyncThunk('profile', async (payload, thunkAPI) => {
	try {
		const response = await getDetail();
		if (response.status) {
			return response.data;
		} else {
			thunkAPI.dispatch(setMessage(response.message));
			return thunkAPI.rejectWithValue();
		}
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

export const subscriptionStatus = createAsyncThunk('subscriptionStatus', async (payload, thunkAPI) => {
	try {
		const response = await getSubscriptionStatus();
		if (response.status) {
			return response.data;
		} else {
			thunkAPI.dispatch(setMessage(response.message));
			return thunkAPI.rejectWithValue();
		}
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

export const updateProfile = createAsyncThunk('profile', async (payload, thunkAPI) => {
	try {
		const response = await updateDetail(payload);

		if (response.status) {
			return response.data;
		} else {
			return thunkAPI.rejectWithValue(response);
		}
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

export const getUserAvatar = createAsyncThunk('avatar', async (payload, thunkAPI) => {
	try {
		const response = await getAvatar();
		return response.data.avatar;
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

const initialState = {
	avatar: null
};

const userSlice = createSlice({
	name: 'profile',
	initialState,
	extraReducers: {
		[profile.fulfilled]: (state, action) => {
			state.profile = action.payload;
		},
		[subscriptionStatus.fulfilled]: (state, action) => {
			state.subscriptionStatus = action.payload;
		},
		[getUserAvatar.fulfilled]: (state, action) => {
			state.avatar = action.payload;
		}
	}
});

const { reducer } = userSlice;
export default reducer;
