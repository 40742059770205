import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unread } from 'app/slices/pusher';

const FetchUnreadMessage = ({ children }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user !== null) {
			dispatch(unread())
		}
	}, []);

	return <div></div>;
};

export default FetchUnreadMessage;
