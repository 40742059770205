import { combineReducers } from '@reduxjs/toolkit';
import AuthSlice from 'app/slices/auth';
import MessageSlice from 'app/slices/message';
import UserSlice from 'app/slices/user';
import PusherSlice from 'app/slices/pusher';

const createReducer = asyncReducers => (state, action) => {
	const combinedReducer = combineReducers({
		auth: AuthSlice,
		user: UserSlice,
		pusher: PusherSlice,
		message: MessageSlice,
		...asyncReducers
	});

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === 'auth/user/userLoggedOut') {
		state = undefined;
	}

	return combinedReducer(state, action);
};

export default createReducer;
