import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import parseJwt from './parseJwt';

const AuthVerify = props => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		const user = JSON.parse(localStorage.getItem('user'));

		if (user) {
			const decodedJwt = parseJwt(user.token);

			if (decodedJwt.exp * 1000 < Date.now()) {
				props.logOut();
			}
		}
		// eslint-disable-next-line
	}, [location, props]);

	return <div></div>;
};

export default AuthVerify;
