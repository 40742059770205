import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAvatar, getDetail, updateDetail } from 'app/services/user';
import { setMessage } from './message';
import { useSelector } from 'react-redux';
import { listUnreadMessages } from 'app/services/threads';

export const unread = createAsyncThunk('unread', async (payload, thunkAPI) => {
	try {
		const response = await listUnreadMessages();
		return response.data;
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

const initialState = {
	avatar: null
};

const pusherSlice = createSlice({
	name: 'pusher',
	initialState,
	extraReducers: {
		[unread.fulfilled]: (state, action) => {
			if (action.payload !== undefined) {
				state.unread = action.payload;
			}
		},
	}
});

const { reducer } = pusherSlice;
export default reducer;
