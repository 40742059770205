import axios from 'axiosInstance.js';

export const getDetail = async () => {
	return axios.get('me');
};

export const getSubscriptionStatus = async () => {
	return axios.get('plan/status');
};

export const updateDetail = async payload => {
	return axios.put('me', payload);
};

export const askResetPassword = async payload => {
	return axios.post('reset-password', payload);
};

export const updatePassword = async payload => {
	return axios.post('me/password', payload);
};

export const updateAvatar = async payload => {
	return axios.post('avatar', payload);
};

export const updateAgency = async payload => {
	return axios.post('agency', payload);
};

export const addAgency = async payload => {
	return axios.post('agency', payload);
};

export const getAvatar = async () => {
	return axios.get('avatar');
};

export const getQuota = async () => {
	return axios.get('quota');
};
