import axios from 'axiosInstance.js';


export const listThreads = async () => {
	return axios.get(`threads`);
};

export const fetchThread = async (threadId) => {
	return axios.get(`thread/${threadId}`);
};

export const listUnreadMessages = async () => {
	return axios.get(`threads/unread`);
};

export const markAsRead = async (threadId) => {
	return axios.post(`threads/${threadId}/markasread`);
};

export const threadMessages = async (threadId) => {
	return axios.get(`threads/${threadId}/messages`);
};

export const sendMessage = async (threadId, payload) => {
	return axios.post(`threads/${threadId}/messages`, payload);
};

export const fetchAttachment = async (threadId, attachmentUUID) => {
	return axios.get(`threads/${threadId}/attachments/${attachmentUUID}`, {
		responseType: 'arraybuffer', // Ensure raw binary data is returned
	});
};

export const toggleArchive = async (threadId) => {
	return axios.get(`threads/${threadId}/toggleArchive`);
};

