import { Fragment, useContext } from 'react'
import { Snackbar as SnackbarPopup, Alert, List, ListItem, ListItemText } from '@mui/material';
import parse from 'html-react-parser';
import SnackbarContext from 'app/snackbar/snackbar-context';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const Snackbar = () => {
	const snackbarCtx = useContext(SnackbarContext);

	const onClose = () => {
		snackbarCtx.onClose();
	};

	if (snackbarCtx.message === null) return null;

	return (
		<>
			{snackbarCtx.severity === 'error' ? (
				<Transition.Root show={true} as={Fragment}>
					<Dialog open={snackbarCtx.isDisplayed} as="div" className="relative z-10" onClose={onClose}>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
										<div className="bg-white px-4 pb-2 pt-5 sm:p-6 sm:pb-4">
											<div className="sm:flex sm:items-start">
												<div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
													<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
												</div>
												<div className="mt-2 sm:ml-4 sm:mt-0 sm:text-left">
													<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
														Attention
													</Dialog.Title>
													<div className="mt-2">
														{snackbarCtx.message.map((item, index) => (
															<p key={index} className="text-sm text-gray-500">
																{parse(item.message)}
															</p>
														))}
													</div>
												</div>
											</div>
										</div>
										<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
											<button
												type="button"
												className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
												onClick={onClose}
											>
												OK
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			) : (
				<SnackbarPopup
					className="text-gray-900 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
					open={snackbarCtx.isDisplayed}
					autoHideDuration={5000}
					onClose={onClose}
				>
					<Alert onClose={onClose} severity={snackbarCtx.severity} sx={{ width: '100%' }}>
						<List>
							{snackbarCtx.message.map((item, index) => (
								<ListItem key={index}>
									<ListItemText primary={item?.name ? parse(`${item.name}: ${item.message}`) : parse(item.message)} />
								</ListItem>
							))}
						</List>
					</Alert>
				</SnackbarPopup>
			)}
		</>
	);
};

export default Snackbar;
