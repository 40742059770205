import axios from 'axiosInstance.js';

export const authRegister = async payload => {
	return axios.post('auth/register', payload);
};

export const authLogin = async payload => {
	return axios.post('auth/login', payload).then(response => {
		if (response.status && response.data.token) {
			localStorage.setItem('user', JSON.stringify(response.data));
		}

		return response;
	});
};

export const authRefresh = async payload => {
	return axios.post('token/refresh', payload).then(response => {
		if (response) {
			localStorage.setItem('user', JSON.stringify(response));
		}

		return response;
	});
};

export const authLogout = () => {
	localStorage.removeItem('user');
	localStorage.removeItem('modalShown');
};

export const reset = async payload => {
	return axios.post('reset-password', payload);
};

export const verifyToken = async payload => {
	return axios.post('reset-password/verify-reset-token', payload);
};

export const saveNewPassword = async payload => {
	return axios.post('reset-password/save-new-password', payload);
};

export const verifyEmail = async (token) => {
	return axios.get(`email/verify/${token}`);
};
