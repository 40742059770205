import { createContext, useState } from 'react';
const SnackbarContext = createContext({
	isDisplayed: false,
	displayMessage: message => {},
	onClose: () => {}
});
export default SnackbarContext;

export const SnackBarContextProvider = props => {
	const [message, setMessage] = useState(null);
	const [severity, setSeverity] = useState('error');
	const [isDisplayed, setIsDisplayed] = useState(false);
	const displayHandler = (message, severity = 'error') => {
		setSeverity(severity);
		setMessage(message);
		setIsDisplayed(true);
	};
	const closeHandler = () => {
		setIsDisplayed(false);
	};
	return (
		<SnackbarContext.Provider
			value={{
				severity,
				message,
				isDisplayed,
				displayMessage: displayHandler,
				onClose: closeHandler
			}}
		>
			{props.children}
		</SnackbarContext.Provider>
	);
};
