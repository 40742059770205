import { nonLoggedInRoutes } from 'app/constants';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import routes from './routes';

const AppRouter = () => {
	const { isLoggedIn } = useSelector(state => state.auth);
	const getRouteComponent = route => {
		// if (route.comingSoon && process.env.REACT_APP_COMING_SOON) {
		// 	return <Navigate to={'/coming-soon'} replace />;
		// }
		if (route.private) {
			return <PrivateRoute Component={route.component} />;
		}
		if (nonLoggedInRoutes.includes(route.path) && isLoggedIn) {
			return <Navigate to={'/home'} replace />;
		}
		return <route.component />;
	};
	return (
		<Routes>
			{routes.map((route, routeIndex) =>
				route.children ? (
					<Route key={routeIndex} path={route.path}>
						{route.children.map((childRoute, childRouteIndex) =>
							childRoute.index ? (
								<Route key={childRouteIndex} index element={getRouteComponent(childRoute)} />
							) : (
								<Route
									key={childRouteIndex}
									path={childRoute.path}
									element={getRouteComponent(childRoute)}
								/>
							)
						)}
					</Route>
				) : (
					<Route
						key={routeIndex}
						exact={route.exact ? true : false}
						path={route.path}
						element={route.redirect ? <Navigate to={route.redirect} replace /> : getRouteComponent(route)}
					/>
				)
			)}
		</Routes>
	);
};

export default AppRouter;
