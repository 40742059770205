import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
	baseURL: API_URL,
	paramsSerializer: params => params
});

instance.interceptors.request.use(x => {
	const user = JSON.parse(localStorage.getItem('user'));
	const token = user?.token ?? '';
	x.headers.Authorization = `Bearer ${token}`;
	return x;
});

instance.interceptors.response.use(
	response => {
		if (response?.data?.message === 'Expired JWT Token') {
			localStorage.removeItem('user');
			window.location.href = '/login?expired';
		} else if (response?.data?.message === 'Invalid access') {
			localStorage.removeItem('user');
			window.location.href = '/login?expired-invalid';
		}
		return response.data;
	},
	error => {
		if (error.response.status === 401) {
			window.location.href = '/unauthorized';
		}
		return Promise.reject(error);
	}
);

export default instance;
