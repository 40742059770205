import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ Component }) => {
	const location = useLocation();
	const { user } = useSelector(state => state.auth);
	const redirectionState = {};

	const patterns = [/^\/register\/.+$/];
	patterns.forEach(pattern => {
		if (location.pathname.match(pattern)) {
			redirectionState.from = location.pathname;
		}
	});

	return !user ? <Navigate to="/login" replace={true} state={redirectionState} /> : <Component />;
};
export default PrivateRoute;
