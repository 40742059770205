import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'app/store';
import App from 'app/App';
import reportWebVitals from './reportWebVitals';
// import './i18n';
import { SnackBarContextProvider } from 'app/snackbar/snackbar-context';
import 'bootstrap/dist/css/bootstrap.css';
import './app/assets/scss/master.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<SnackBarContextProvider>
			<App />
		</SnackBarContextProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
