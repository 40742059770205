import React from 'react';

const routes = [
	{
		path: '/',
		redirect: '/login',
		exact: true
	},
	{
		path: '/login',
		component: React.lazy(() => import('app/pages/login')),
		exact: true
	},
	{
		path: '/create/step1',
		component: React.lazy(() => import('app/pages/register')),
		exact: true
	},
	{
		path: '/create/step2',
		component: React.lazy(() => import('app/pages/register')),
		exact: true
	},
	{
		path: '/create/step3',
		component: React.lazy(() => import('app/pages/register')),
		private: true // Requires authentication
	},
	{
		path: '/create/step4',
		component: React.lazy(() => import('app/pages/register')),
		private: true // Requires authentication
	},
	{
		path: '/create/step5',
		component: React.lazy(() => import('app/pages/register')),
		private: true // Requires authentication
	},
	{
		path: '/create/confirmation',
		component: React.lazy(() => import('app/pages/register')),
		private: true // Requires authentication
	},
	{
		path: '/mot-de-passe-oublie',
		component: React.lazy(() => import('app/pages/forgot-password')),
		exact: true
	},
	{
		path: '/mot-de-passe-oublie/:token/:email',
		component: React.lazy(() => import('app/pages/forgot-password')),
	},
	{
		path: '/verification-email/:token',
		component: React.lazy(() => import('app/pages/login/verify')),
		exact: true
	},
	{
		path: '/profile',
		private: true,
		component: React.lazy(() => import('app/pages/profile'))
	},
	{
		path: '/home',
		private: true,
		component: React.lazy(() => import('app/pages/home'))
	},
	// GLOBAL SEARCH ROUTES
	{
		path: '/page/recherche-biens',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/search'))
	},

	// synthesis-matching
	{
		path: '/page/synthese-rapprochement',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/synthesis-matching'))
	},

	// synthesis-matching
	{
		path: '/page/matching',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/matching'))
	},

	// CLIENTS ROUTES
	{
		path: '/page/portefeuille-client',
		private: true,
		component: React.lazy(() => import('app/pages/client/listing.js'))
	},
	{
		path: '/page/portefeuille-client/ajout',
		private: true,
		component: React.lazy(() => import('app/pages/client/form'))
	},
	{
		path: '/page/portefeuille-client/:clientId',
		private: true,
		component: React.lazy(() => import('app/pages/client/index'))
	},
	{
		path: '/page/portefeuille-client/:clientId/projet/:projectId/rapprochement',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/client/rapprochement'))
	},
	// PROPERTY ROUTES
	{
		path: '/page/portefeuille-bien',
		private: true,
		component: React.lazy(() => import('app/pages/property/listing.js'))
	},
	{
		path: '/page/portefeuille-bien/ajout',
		private: true,
		component: React.lazy(() => import('app/pages/property/form'))
	},
	{
		path: '/page/portefeuille-bien/:propertyId',
		private: true,
		component: React.lazy(() => import('app/pages/property/index'))
	},
	{
		path: '/page/portefeuille-bien/:propertyId/rapprochement',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/property/rapprochement'))
	},

	// THREAD ROUTES
	{
		path: '/messagerie',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/threads'))
	},
	{
		path: '/messagerie/:threadId',
		private: true,
		comingSoon: true,
		component: React.lazy(() => import('app/pages/threads/viewMessage'))
	},

	// ERROR
	{
		path: '/paiement-requis',
		component: React.lazy(() => import('app/pages/payment-required'))
	},

	// ERROR
	{
		path: '*',
		component: React.lazy(() => import('app/pages/not-found'))
	},

	// ERROR
	{
		path: '/unauthorized',
		component: React.lazy(() => import('app/pages/unauthorized'))
	},

	// ERROR
	{
		path: '/coming-soon',
		component: React.lazy(() => import('app/pages/coming-soon'))
	}

];

export default routes;
