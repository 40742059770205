// PusherContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import { unread } from './slices/pusher';
const PusherContext = createContext();

export const PusherProvider = ({ children, onNewMessage }) => {
    const [pusher, setPusher] = useState(null);
    const profileData = useSelector(state => state.user.profile);
    const user = JSON.parse(localStorage.getItem('user'));
    const AUTH_TOKEN = user?.token ?? '';

    useEffect(() => {
        window.Pusher = require("pusher-js");

        const newPusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            authEndpoint: process.env.REACT_APP_API_BROADCASTING_URL,
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            encrypted: true,
            auth: {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                },
            },
        });

        setPusher(newPusher);

        return () => {
            newPusher.disconnect();
        };


    }, [AUTH_TOKEN]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (pusher && profileData) {
            const channel = pusher.subscribe('private-unread.' + profileData.id);

            channel.bind('message.unread', function (data) {
                dispatch(unread());
            });
        }
        // ... existing code
    }, [profileData, onNewMessage]);

    return (
        <PusherContext.Provider value={{ pusher }}>
            {children}
        </PusherContext.Provider>
    );
};

export const usePusher = () => {
    const context = useContext(PusherContext);
    if (!context) {
        throw new Error('usePusher must be used within a PusherProvider');
    }
    return context;
};
