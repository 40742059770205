import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import parseJwt from './parseJwt';
import { useSelector } from 'react-redux';
import FullScreenLoading from 'app/components/common/FullScreenLoading';

const InvoiceVerify = props => {
	const location = useLocation();
	const subscriptionStatus = useSelector(state => state.user.subscriptionStatus);
	const { isLoggedIn } = useSelector(state => state.auth);
	useEffect(() => {
		if (isLoggedIn && subscriptionStatus?.latest_payment_status == "requires_payment_method") {
			if (location.pathname !== '/paiement-requis') {
				window.location.href = '/paiement-requis';
			}

		}
		// eslint-disable-next-line
	}, [location, subscriptionStatus]);

	return <div></div>;
};

export default InvoiceVerify;
