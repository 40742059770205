import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';

import { authRegister, authLogin, authLogout, authRefresh } from '../services/auth';

const user = JSON.parse(localStorage.getItem('user'));

export const register = createAsyncThunk('auth/register', async (payload, thunkAPI) => {
	try {
		const response = await authRegister(payload);
		return response;
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

export const login = createAsyncThunk('auth/login', async (payload, thunkAPI) => {
	try {
		const response = await authLogin(payload);
		if (response.status) {
			return { user: response.data };
		} else {
			return thunkAPI.rejectWithValue(response.message);
		}
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

export const logout = createAsyncThunk('auth/logout', async () => {
	await authLogout();
});

export const refresh = createAsyncThunk('auth/refresh', async (payload, thunkAPI) => {
	try {
		const response = await authRefresh(payload);
		if (response.token) {
			return { user: response };
		} else {
			return thunkAPI.rejectWithValue(response.message);
		}
	} catch (error) {
		const message =
			(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
		thunkAPI.dispatch(setMessage(message));
		return thunkAPI.rejectWithValue();
	}
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
	name: 'auth',
	initialState,
	extraReducers: {
		[register.fulfilled]: (state, action) => {
			state.isLoggedIn = false;
		},
		[register.rejected]: (state, action) => {
			state.isLoggedIn = false;
		},
		[login.fulfilled]: (state, action) => {
			state.isLoggedIn = true;
			state.user = action.payload.user;
		},
		[login.rejected]: (state, action) => {
			state.isLoggedIn = false;
			state.user = null;
		},
		[logout.fulfilled]: (state, action) => {
			state.isLoggedIn = false;
			state.user = null;
		},
		[refresh.fulfilled]: (state, action) => {
			state.isLoggedIn = true;
			state.user = action.payload.user;
		},
		[refresh.rejected]: (state, action) => {
			state.isLoggedIn = false;
			state.user = null;
		}
	}
});

const { reducer } = authSlice;
export default reducer;
