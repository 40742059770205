import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { profile, subscriptionStatus } from 'app/slices/user';
import { logout } from 'app/slices/auth';
import { clearMessage } from 'app/slices/message';
import parseJwt from 'app/common/parseJwt';

const RestoreLogin = ({ children }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		//check local storage and dispatch refresh
		const user = JSON.parse(localStorage.getItem('user'));
		if (user !== null) {
			const decodedJwt = parseJwt(user.token);
			if (decodedJwt.exp * 1000 > Date.now()) {
				dispatch(profile())
					.then(response => {
						if (response.error) {
							dispatch(logout());
							dispatch(clearMessage());
						}
						dispatch(subscriptionStatus())
					})
					.catch(error => {
						console.log(error);
					});
			}
		}
		// eslint-disable-next-line
	}, []);

	return <div></div>;
};

export default RestoreLogin;
